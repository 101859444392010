import React, { useEffect, useState, useContext, useRef } from 'react'
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import '../App.css';
import audio_thumbnail from '../assets/images/audio_thumbnail.jpg';
import '../styles/Home.css';
import { playlistData } from '../layouts/DefaultLayout';
import playIcon from '../assets/images/playIcon.gif';
import queueList from '../assets/images/queueList.png'

function Footer() {
  const audioPlayerRef = useRef(null);
  const [currentTrackIndex, setCurrentTrackIndex] = useState(0);
  const [audios, setAudios] = useState([]);
  const [queueListViews, setQueueListViews] = useState(false)
  const [detailList,setDetailList]=useState(false)
  const [detailView, setDetailView] = useState()
  const [action, setAction] = useState("play")
  const playlist = useContext(playlistData);
  useEffect(() => {
    if (Array.isArray(playlist?.playlistRecords)) {
      setCurrentTrackIndex(playlist?.playlistRecords?.[1] ? playlist?.playlistRecords?.[1] : 0);
      setAudios(playlist?.playlistRecords?.[0] ? playlist?.playlistRecords?.[0] : []);
    }
  }, [playlist])
  const clearAudio = () => {
    setCurrentTrackIndex(0);
    setAudios([]);
    playlist?.setPlaylistRecords([]);
    setQueueListViews(false)
    setDetailList(false)
    setDetailView()
    sessionStorage.removeItem('action')
  }
  const handleClickPrevious = () => {
    setCurrentTrackIndex((prevIndex) => prevIndex === 0 ? audios.length - 1 : prevIndex - 1);
    let index = currentTrackIndex > 0 ? currentTrackIndex - 1 : audios?.length - 1
    playlist?.setPlaylistRecords([audios, index]);
  };

  const handleClickNext = () => {
    setCurrentTrackIndex((prevIndex) => prevIndex < audios.length - 1 ? prevIndex + 1 : 0);
    let index = currentTrackIndex < audios?.length - 1 ? currentTrackIndex + 1 : 0
    playlist?.setPlaylistRecords([audios, index]);
  };

  const handleEnd = () => {
    if (currentTrackIndex === audios?.length - 1) {
      audioPlayerRef.current.audio.current.pause();
    } else {
      setCurrentTrackIndex((prevIndex) => prevIndex < audios.length - 1 ? prevIndex + 1 : 0);
      let index = currentTrackIndex < audios?.length - 1 ? currentTrackIndex + 1 : 0
      playlist?.setPlaylistRecords([audios, index]);
    }
  };
  const handlePlay = (index) => {
    setCurrentTrackIndex(index);
    playlist?.setPlaylistRecords([audios, index]);
  }
  useEffect(() => {
    if(detailView){
      setDetailView(audios?.[currentTrackIndex])
    }
    // eslint-disable-next-line
  },[audios?.[currentTrackIndex]])
  const isMobile = window.innerWidth < 768;
  return (
    <>
      {
        queueListViews && 
    <div className='card queueListView'>
      <div className='d-flex justify-content-between align-items-center p-3'>
        <h6>Queue</h6>
        <span role='button' title="Close" onClick={() => setQueueListViews(false)}>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
          </svg>
        </span>
      </div>
      <div className='overflow-y-auto overflow-x-hidden mb-2'>
        {
          audios?.map((item, index) => {
            return (
              <div className={audios?.[currentTrackIndex]?.id === item?.id ? 'row p-1 queue_active align-items-center' : 'row p-1 align-items-center'} key={index} role='button' onClick={() => handlePlay(index)}>
                <div className='col-lg-2 col-2'>
                  <img src={item?.thumbnail ? item?.thumbnail : audio_thumbnail} alt="audio" className='audioImage' height="40" width="40" />
                </div>
                <div className='col-lg-8 col-8'>
                  <div className='fw-bold description' title={item?.title}>{item?.title}</div>
                  <span className='description' title={item?.artist?.name}>{item?.artist?.name}</span>
                </div>
                <div className='col-lg-2 col-2'>
                  <span>{(audios?.[currentTrackIndex]?.id === item?.id && action === "play") && <img src={playIcon} height={30} width={30} alt="play" />}</span>
                </div>
              </div>
            )
          })
        }
      </div>
    </div>
      }
      {
        detailList && 
        <div className="card detailView">
                <div className='d-flex justify-content-end align-items-center p-3'>
                  <span role='button' title="Close" onClick={() => { setDetailView(); setQueueListViews(false);setDetailList(false) }}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
                    <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
                  </svg></span>
                </div>
                <div className='p-3 overflow-y-auto overflow-x-hidden mb-2'>
                  <div className='row align-items-center'>
                    <div className='col-lg-4'>
                      <img src={detailView?.thumbnail ? detailView?.thumbnail : audio_thumbnail} alt="audio" style={{ height:'auto', maxWidth: '100%' }} className='rounded-4' />
                    </div>
                    <div className='col-lg-8'>
                      <div className='fw-bold p-2'>{detailView?.title}</div>
                      <span className='p-2'>{detailView?.artist?.name}</span>
                      <div className='p-2 text-muted'>{detailView?.sub_category}</div>
                      <div className='px-2'>
                      <span className='badge rounded-pill bg-secondary text-wrap p-1 m-1'>{detailView?.category?.category_name}</span>
                      <span className='badge rounded-pill bg-secondary text-wrap p-1 m-1'>{detailView?.language?.name}</span>
                      <span className='badge rounded-pill bg-secondary text-wrap p-1 m-1'>{detailView?.tags}</span>
                      </div></div>
                    <div className='p-2 text-muted'>{detailView?.description}</div>
                    {
                      detailView?.lyrics ?
                      <>
                      <div className='p-2 fw-bold'>Lyrics</div>
                    <div className='p-2' dangerouslySetInnerHTML={{ __html: detailView?.lyrics?.replace(/\n/g, '<br/>')}}></div></>
                    : <span className='text-muted text-center p-2 fw-bold'>No lyrics found</span>
                    }
                  </div>
                </div>
              </div>
      }
      <div className={audios.length > 0 ? 'p-2' : ''} style={{ marginTop: audios.length > 0 ? "70px" : '0px' }}>
        {
            audios.length > 0 ?
              <div className='fixed-bottom audioFooter'>
                {
                  isMobile? 
                  <div className='row d-flex align-items-center mt-1'>
                    <div className='col-2' role='button' onClick={() => { setQueueListViews(false);setDetailView(audios?.[currentTrackIndex]); setDetailList(!detailList)}}>
                    <img src={audios?.[currentTrackIndex]?.thumbnail ? audios?.[currentTrackIndex]?.thumbnail : audio_thumbnail} alt="audio" className='audioImage' height={30} width={30} />
                    </div>
                    <div className='col-6' role='button' onClick={() => { setQueueListViews(false);setDetailView(audios?.[currentTrackIndex]); setDetailList(!detailList)}}>
                    <div className='fw-bold description small'>{audios?.[currentTrackIndex]?.title}</div>
                    <span className='description small'>{audios?.[currentTrackIndex]?.artist?.name}</span>
                    </div>
                    <div className='col-4 p-0'>
                    <>
                    <span className='text-decoration-underline lyricsTextColor fw-bold p-1' role='button' title="Lyrics" onClick={() => { setDetailList(!detailList);setDetailView(audios?.[currentTrackIndex]);setQueueListViews(false)}}>
                        Lyrics
                      </span>
                    <span className='text-dark' role='button' title="Queue list" onClick={() => {setQueueListViews(!queueListViews);setDetailView();setDetailList(false)}}>
                    <img src={queueList} alt="queue" width="35" height="35" className='rounded' />
                      </span>
                      <span className='text-dark' role='button' title="Close" onClick={clearAudio}>
                        <svg xmlns="http://www.w3.org/2000/svg" width={16} fill="currentColor" className="bi bi-x-circle" viewBox="0 0 16 16">
                          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                          <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                        </svg>
                      </span>
                    </>
                    </div>
                    <div className='col-12'>
                      <AudioPlayer
                      ref={audioPlayerRef}
                      autoPlay
                      src={audios?.[currentTrackIndex]?.audio_url}
                      onPlay={() => {setAction("play");sessionStorage.setItem("action","play"); const event = new Event('actionChange'); window.dispatchEvent(event)}}
                      onPause={() => {setAction("pause");sessionStorage.setItem("action","pause"); const event = new Event('actionChange'); window.dispatchEvent(event)}}
                      loop={false}
                      style={{ backgroundColor: "transparent", boxShadow: "none" }}
                      onEnded={handleEnd}
                      showSkipControls
                      showJumpControls={false}
                      onClickPrevious={handleClickPrevious}
                      onClickNext={handleClickNext}
                    />
                    </div>
                  </div>:
                  <div className='row'>
                  <div className='col-lg-4 col-3 row align-items-center d-flex' role='button' onClick={() => { setDetailList(!detailList);setDetailView(audios?.[currentTrackIndex]);setQueueListViews(false)}}>
                    <div className='col-lg-3'>
                      <img src={audios?.[currentTrackIndex]?.thumbnail ? audios?.[currentTrackIndex]?.thumbnail : audio_thumbnail} alt="audio" className='audioImage' height={60} width={"100%"} />
                    </div>
                    <div className='col-lg-9'>
                      <div className='fw-bold description'>{audios?.[currentTrackIndex]?.title}</div>
                      <span className='description'>{audios?.[currentTrackIndex]?.artist?.name}</span>
                    </div>
                  </div>
                  <div className='col-lg-4 d-flex justify-content-center align-items-center'>
                    <AudioPlayer
                      ref={audioPlayerRef}
                      autoPlay
                      src={audios?.[currentTrackIndex]?.audio_url}
                      onPlay={() => {setAction("play");sessionStorage.setItem("action","play"); const event = new Event('actionChange'); window.dispatchEvent(event)}}
                      onPause={() => {setAction("pause");sessionStorage.setItem("action","pause"); const event = new Event('actionChange'); window.dispatchEvent(event)}}
                      loop={false}
                      style={{ backgroundColor: "transparent", boxShadow: "none" }}
                      onEnded={handleEnd}
                      showSkipControls
                      showJumpControls={false}
                      onClickPrevious={handleClickPrevious}
                      onClickNext={handleClickNext}
                    />
                  </div>
                  <div className='col-lg-4 d-flex justify-content-end align-items-center'>
                    <div className='me-3'>
                      <span className='p-5 text-decoration-underline lyricsTextColor fw-bold' role='button' title="Lyrics" onClick={() => { setDetailList(!detailList);setDetailView(audios?.[currentTrackIndex]);setQueueListViews(false)}}>
                        Lyrics
                      </span>
                      <span className='text-dark' role='button' title="Queue list" onClick={() => {setQueueListViews(!queueListViews);setDetailView();setDetailList(false)}}>
                        <img src={queueList} alt="queue" width="50" height="50" className='rounded' />
                      </span>
                      <span className='text-dark p-2' role='button' title="Close" onClick={clearAudio}>
                      <svg xmlns="http://www.w3.org/2000/svg" width={20} fill="currentColor" class="bi bi-x-circle" viewBox="0 0 16 16">
  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
</svg>
                      </span>
                    </div>
                  </div>
                </div>
                }
                
              </div>
              : ""
        }
      </div>
    </>
  )
}

export default Footer