import React, { useContext } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import PhoneInput from 'react-phone-input-2'
import { addUpdateAPI } from '../apiServices/ApiService'
import Swal from 'sweetalert2'
import { playlistData } from '../layouts/DefaultLayout'

const schema = yup.object().shape({
    fullname: yup.string().required('Name is required'),
    email: yup.string().matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        'Invalid email'
      ).required('Email is required'),
    phone: yup.string().optional(),
    phone_code: yup.string(),
    description: yup.string().required('Description is required'),
    attachment: yup.mixed().notRequired()
})
function Feedback({ handleCancel }) {
    const { register, handleSubmit, reset, setValue, setError, clearErrors, formState: { errors } } = useForm({ resolver: yupResolver(schema) })
    const handleClose = () => {
        reset()
        handleCancel()
    }
    const context=useContext(playlistData)
    const handlePhoneChange = (phone, country) => {
        let number = phone?.slice(country?.dialCode?.length)
        setValue('phone_code', country?.dialCode);
        setValue('phone', number);
    }
    const onSubmit = (data) => {
        if (data?.attachment[0]) {
            //check file type
            if (data?.attachment[0]?.type !== "image/jpeg" && data?.attachment[0]?.type !== "image/jpg" && data?.attachment[0]?.type !== "image/png") {
                setError("attachment", { type: "custom", message: "Only .jpg, .jpeg and .png files are allowed" })
                return
            } else {
                clearErrors("attachment")
            }
        }
        const sendData = {
            "fullname": data?.fullname,
            "email": data?.email,
            "phone_number": data?.phone,
            "country_code": data?.phone_code,
            "description": data?.description,
        }
        const fd = new FormData()
        if (data?.attachment[0]) {
            fd.append("attachment", data?.attachment[0])
        }
        fd.append("data", JSON.stringify(sendData))
        addUpdateAPI("POST", "/help/", fd).then((res) => {
            if (res?.data?.status) {
                Swal.fire({
                    toast: true,
                    position: 'top-end',
                    icon: 'success',
                    title: 'Feedback sent successfully',
                    showConfirmButton: false,
                    timer: 1500
                })
                handleClose()
            } else {
                Swal.fire({
                    toast: true,
                    icon: 'error',
                    title: res?.data?.detail,
                    showConfirmButton: false,
                    timer: 1500
                })
            }
        }).catch((err) => {
            console.log(err)
        })
    }
    return (
        <div className={`card p-3 rounded-4 ${context?.theme==="dark"?"helpCard":"helpCardLight"}`}>
            <p className='text-center fw-bold'>Having any problem?</p>
            <form onSubmit={handleSubmit(onSubmit)}>
                <input type="text" placeholder='Name*' className={`form-control ${context?.theme==="dark"?"helpInput":""}`} {...register('fullname')} />
                {errors.fullname && <span className='text-danger'>{errors.fullname.message}</span>}
                <input type="email" placeholder='Email*' className={`form-control mt-2 ${context?.theme==="dark"?"helpInput":""}`} {...register('email')} />
                {errors.email && <span className='text-danger'>{errors.email.message}</span>}
                <PhoneInput
                    className='mt-2'
                    country={'in'}
                    enableSearch
                    placeholder='Phone Number'
                    inputStyle={{background:"transparent",color:context?.theme==="dark"?"white":"black",width:"265px" }}
                    onChange={(phone, country) => handlePhoneChange(phone, country)}/>
                <textarea placeholder='Describe your problem*' className={`form-control mt-2 ${context?.theme==="dark"?"helpInput":""}`} {...register('description')} />
                {errors.description && <span className='text-danger'>{errors.description.message}</span>}
                <input type="file" accept="image/*" placeholder='Attachment' className={`form-control mt-2 ${context?.theme==="dark"?"helpInput":""}`} {...register('attachment')} style={{color:"white"}}/>
                {errors.attachment && <span className='text-danger'>{errors.attachment.message}</span>}
                <div className='d-flex justify-content-end mt-3'>
                    <button type='button' className='btn btn-secondary btn-sm me-2 rounded-4' onClick={() => handleClose()}><small>Close</small></button>
                    <button type='submit' className='btn actionBtn btn-sm rounded-4'><small>Send</small></button>
                </div>
            </form>
        </div>
    )
}

export default Feedback