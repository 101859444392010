import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import loadingGif from '../assets/images/Loading_icon.gif';
import { addUpdateAPI } from '../apiServices/ApiService';

function RegistrationVerify() {
    const data = useLocation().search
    const token = new URLSearchParams(data).get('token');
    const [loading, setLoading] = useState(true)
    const [response, setResponse] = useState({ status: null, message: '' })
    const verifyRegistration = () => {
        addUpdateAPI('POST', `/admin/verify/?token=${token}`).then((res) => {
        setLoading(false)
        if(res.data.status){
            setResponse({ status: true, message: 'Registration verified successfully' })
        }
        else{
            setResponse({ status: false, message: 'Registration verification failed' })
        }
        }).catch((err) => {
            setLoading(false)
            setResponse({ status: false, message: 'Registration verification failed' })
            console.log(err)
        })
    }
    useEffect(() => {
        verifyRegistration()
        //eslint-disable-next-line
    }, [])
    return (
        <div className="container-fluid justify-content-center">
            <div className={loading ? "" : "row align-items-center min vh-100"}>
                {
                    loading ?
                        <div className="col-lg-6 col-md-8 col-sm-12 col-xs-12 col-12 mx-auto text-center">
                            <img src={loadingGif} alt="loading" height={"30px"} />
                        </div>
                        :
                        <div className="col-lg-6 col-md-8 col-sm-12 col-xs-12 col-12 mx-auto">
                            <div className="card card-default card-primary card-outline">
                                <div className="card-body bg-light text-center">
                                    {
                                        response?.status===true ?
                                            <svg xmlns="http://www.w3.org/2000/svg" style={{ color: "#039d79" }} width="60" height="60" fill="currentColor" className="bi bi-check-circle" viewBox="0 0 16 16">
                                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                                            </svg>
                                            :
                                        response?.status===false ?
                                            <svg xmlns="http://www.w3.org/2000/svg" style={{ color: "#b30000" }} width="60" height="60" fill="currentColor" className="bi bi-x-circle" viewBox="0 0 16 16">
                                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                            </svg>
                                            :''
                                    }
                                    <h3 className='mt-2'>{response?.message}</h3>
                                </div>
                                <div className="text-center">
                                    <Link to="/login" className="btn btn-sm text-decoration-underline text-primary" target='_blank'>Click here to login</Link>
                                </div>
                            </div>
                        </div>
                }
            </div>
        </div>
    )
}

export default RegistrationVerify